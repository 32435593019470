<template>
    <v-text-field
        :value="value"
        :name="name"
        :label="labelText"
        :error-messages="errorMessages"
        :suffix="currencyCode"
        :autofocus="autofocus"
        :hide-details="hideDetails"
        :single-line="singleLine"
        step="1"
        @input="$emit('input', $event)"
    ></v-text-field>
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

export default {
    mixins: [ErrorMsgInputMixin],
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        autofocus: {
            type: Boolean,
        },
        hideDetails: {
            type: [Boolean, String],
            default: undefined,
        },
        singleLine: {
            type: [Boolean],
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
        }),
    },
}
</script>
