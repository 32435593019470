<template lang="pug">
    .map-wrapper(:id="_uid")
        transition(name="fade")
            .map-overlay(v-if="loading")
                v-progress-circular(:size="30" color="primary" indeterminate)
        gmap-map(
            ref="map"
            :center="defaultCenter"
            :zoom="zoom"
            :options="options"
            class="input-map-frame")
            gmap-marker(
                :position="point"
                :draggable="draggable"
                @dragend="markerDragEnd")
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import GoogleMapAddressMixin from '../InputMixins/GoogleMapAddressMixin'

export default {
    mixins: [GoogleMapAddressMixin],
    props: {
        zoom: {
            type: Number,
            default: 16,
        },
        draggable: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Object,
            default() {
                return {
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                }
            },
        },
        updateAddressDetails: {
            type: Boolean,
        },
        name: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            innerLongitude: null,
            innerLatitude: null,
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            defaultCenter: configType.getters.GOOGLE_MAPS_DEFAULT_CENTER,
        }),
        point() {
            return {
                lat:
                    this.latitude ||
                    this.innerLatitude ||
                    this.defaultCenter.lat,
                lng:
                    this.longitude ||
                    this.innerLongitude ||
                    this.defaultCenter.lng,
            }
        },
    },
    watch: {
        point(point) {
            this.centerOnMap(point)
        },
        value(value) {
            if (value && !this.latitude && !this.longitude) {
                this.loading = true
                this.$gmapApiPromiseLazy()
                    .then(() => {
                        this.$geocoder.geocode(
                            { address: value },
                            (results, status) => {
                                if (status === 'OK' && results.length > 0) {
                                    const address = this.convertAddress(
                                        this.parseAddress(results[0])
                                    )
                                    this.updateAddressFields(address)
                                    this.innerLatitude = address.latitude
                                    this.innerLongitude = address.longitude
                                }
                            }
                        )
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    },
    mounted() {
        this.loading = true
        this.$gmapApiPromiseLazy()
            .then(() => {
                this.$geocoder = new google.maps.Geocoder()
                if (
                    this.updateAddressDetails &&
                    this.latitude != null &&
                    this.longitude != null
                ) {
                    this.updateAddress(this.point).finally(() => {
                        this.loading = false
                    })
                } else {
                    this.loading = false
                }
            })
            .catch(() => {
                this.loading = false
            })
        this.centerOnMap(this.point)
    },
    methods: {
        centerOnMap(point) {
            this.$refs.map.$mapPromise.then((map) => {
                map.panTo(point)
            })
        },
        updateAddress(point) {
            return new Promise((resolve) => {
                this.$geocoder.geocode(
                    {
                        location: point,
                    },
                    (results, status) => {
                        if (status !== 'OK') {
                            this.$emit('update:latitude', point.lat)
                            this.$emit('update:longitude', point.lng)
                            resolve()
                            return
                        }

                        let result = results.find(
                            (result) =>
                                result.geometry.location.lat() === point.lat &&
                                result.geometry.location.lng() === point.lng
                        )
                        if (!result)
                            result =
                                results.find(
                                    (result) =>
                                        result.formatted_address === this.value
                                ) || results[0]

                        const address = this.convertAddress(
                            this.parseAddress(result)
                        )
                        this.updateAddressFields(address)
                        resolve()
                    }
                )
            })
        },
        markerDragEnd(event) {
            this.updateAddress({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            })
        },
    },
}
</script>
